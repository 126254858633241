import Default from '../../Pages/DashBoard/Default/Default';
import ExibirPeca from '../../_IA/Pages/Pecas/Exibir';
import ListarPecas from '../../_IA/Pages/Pecas/Listar';
import CadastrarPeca from '../../_IA/Pages/Pecas/Cadastrar';
import AlterarPeca from '../../_IA/Pages/Pecas/Alterar';

export const routes = [
    { path: `${process.env.PUBLIC_URL}/dashboard/default/`, Component: <Default /> },
    { path: `${process.env.PUBLIC_URL}/pecas/:id`, Component: <ExibirPeca /> },
    { path: `${process.env.PUBLIC_URL}/pecas`, Component: <ListarPecas /> },
    { path: `${process.env.PUBLIC_URL}/pecas/nova/`, Component: <CadastrarPeca /> },
    { path: `${process.env.PUBLIC_URL}/pecas/alterar/:id`, Component: <AlterarPeca /> },
];