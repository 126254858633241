import axios from 'axios';
import { PecasApi } from '../../../api';

async function AlterarPeca(id, data) {
  try {
    console.log('AlterarPeca.data', data);
    const response = await axios.put(`${PecasApi}/${id}`, data);
    return response.data.mensagem;
  } catch (error) {
    console.error('Error fetching data:', error); // Log the error for debugging
    return []; // Return an empty array in case of error
  }
}

export default AlterarPeca;