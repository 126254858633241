import React, { Fragment, useState } from 'react';
import { H5 } from '../../../../AbstractElements';
import PecaDetalhes from './PecaDetalhes';
import { CardHeader, Card, Col, Container, Row } from 'reactstrap';
import AlterarPeca from '../../../Helper/pecas/peca/AlterarPeca';
import GetPecaDados from '../../../Data/Pecas/peca-by-id';


const AlterarPecaContain = () => {
  const [result, setResult] = useState(null); // Initial state
  const [isLoadingSalvamento, setIsLoading] = useState(true); // Track loading state
  const { peca, isLoading } = GetPecaDados();

  const dadosPecaDetalhes = {
    nome: '',
    descricao: '',
    arquivoPrincipal: '',
    HTML: '',
    PDF3D: '',
    PDF2D: '',
    projetoSolidWorks: '',
    SLT: '',
  };

  if (peca) {
    dadosPecaDetalhes.id = peca.id;
    dadosPecaDetalhes.nome = peca.nome;
    dadosPecaDetalhes.descricao = peca.descricao;
    dadosPecaDetalhes.arquivoPrincipal = peca.pathArquivoPrincipal;
    dadosPecaDetalhes.HTML = peca.pathHTML;
    dadosPecaDetalhes.PDF3D = peca.pathPDF3D;
    dadosPecaDetalhes.PDF2D = peca.pathPDF2D;
    dadosPecaDetalhes.projetoSolidWorks = peca.pathProjetoSolidWorks;
    dadosPecaDetalhes.SLT = peca.pathSLT;
  }

  const onAlterarPeca = async (id, data) => {
    try {
      const result = await AlterarPeca(id, data);
      setResult(result);
    } catch (error) {
      // Handle errors appropriately, e.g., display an error message
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
      {isLoading ? (
          <div className="loading-indicator">Carregando...</div>
        ) : (
          <div>
            <Row>
              <Col sm="12" xl="6">
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader className="pb-0">
                        <H5> Alterar peça </H5>
                      </CardHeader>
                      <PecaDetalhes dadosPeca={dadosPecaDetalhes} onAlterarPeca={onAlterarPeca} />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </Fragment>
  );
};
export default AlterarPecaContain;