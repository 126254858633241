import React, { Fragment, useState } from "react";
import { Form, FormGroup, Col, CardBody, CardFooter, Label } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Btn, H5 } from "../../../../AbstractElements";
import FormHeader from "../../../../Auth/Tabs/LoginTab/FormHeader";
import { LoginURL } from "../../../api";


const LoginTab = ({ selected }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [token, setToken] = useState(null); // Store the received token

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        LoginURL,
        { email: data.txtEmail, password: data.txtSenha }
      );
      const token = response.data.token; // Assuming the response contains a "token" property
      if (token) {
        setToken(token); // Save the token
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem("token", token);
        navigate(`${process.env.PUBLIC_URL}/dashboard/default/`);
      }


    } catch (error) {
      console.error(error);
      // Handle login errors
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Form className="theme-form login-form1" noValidate="" onSubmit={handleSubmit(onSubmit)}>
        <FormHeader selected={selected} />
        <div className="login-social-title1">
        <H5>Login com Email</H5>{" "}
        </div>
        <CardBody>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="txtEmail">Email</Label>
            <Col sm="9">
              <input {...register('txtEmail', { required: true })} className="form-control" name="txtEmail" type="text" placeholder="Email" />
              <span>{errors.txtEmail && 'Email é obrigatório'}</span>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="txtSenha" >Senha</Label>
            <Col sm="9">
              <input {...register('txtSenha', { required: true })} className="form-control" name="txtSenha" type="password" placeholder="Senha" />
              <span>{errors.txtSenha && 'Informe sua senha'}</span>
            </Col>
          </FormGroup>
        </CardBody>
        <CardFooter className="pt-0">
          <Btn attrBtn={{ color: 'primary', className: 'me-2'}}>Enviar</Btn>
          <Btn attrBtn={{ color: 'secondary', onClick: () => reset() }}>Cancelar</Btn>
        </CardFooter>
      </Form>
    </Fragment>
 );
};

export default LoginTab;
