import axios from 'axios';
import { PecasApi } from '../../../api';

async function FetchPecas() {
  try {
    const response = await axios.get(PecasApi);
    return response.data.pecas; // Return the fetched data (pecas array)
  } catch (error) {
    console.error('Error fetching data:', error); // Log the error for debugging
    return []; // Return an empty array in case of error
  }
}

export async function FetchPecaById(id) {
  try {
    const response = await axios.get(`${PecasApi}/${id}`);
    return response.data.peca; // Return the fetched data (peca array)
  } catch (error) {
    console.error('Error fetching data:', error); // Log the error for debugging
    return []; // Return an empty array in case of error
  }
}

export default FetchPecas;