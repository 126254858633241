import PecasLista, { pecasColunas } from '../../../Data/Pecas/peca-lista';
import React, { Fragment } from 'react';
import DataTable from 'react-data-table-component';

const PecasTableData = (props) => {
  const dados = PecasLista(props.listarPecas, props.onAceitarDeletarPeca);

  return (
    <Fragment>
      <div className="table-responsive product-table">
        <DataTable
          noHeader
          pagination
          paginationServer
          columns={pecasColunas}
          data={dados}
        />
      </div>
    </Fragment>
  );
};
export default PecasTableData;