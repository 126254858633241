import axios from 'axios';
import { PecasApi } from '../../../api';

async function CadastrarPeca(data) {
  try {
    const response = await axios.post(PecasApi, data);
    return response.data.mensagem;
  } catch (error) {
    console.error('Error fetching data:', error); // Log the error for debugging
    return []; // Return an empty array in case of error
  }
}

export default CadastrarPeca;