import { DownloadAPIPrivada } from ".";
import axios from "axios";

const handleDownload = async (pathArquivo) => {
    try {
        const posicaoUltimaBarra = pathArquivo.lastIndexOf('/');
        let nomeArquivo = '';

        if (posicaoUltimaBarra !== -1) {
            nomeArquivo = pathArquivo.substring(posicaoUltimaBarra + 1);
        } else {
            nomeArquivo = pathArquivo;
        }

        pathArquivo = `${DownloadAPIPrivada}/${pathArquivo}`;
        const response = await axios.get(pathArquivo);

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo); // Set download filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          throw new Error('Failed to download file');
        }
      } catch (err) {
        console.error('Error downloading file:', err);
      }
  };

export default handleDownload;