import React, { useEffect, useState } from 'react';
import Context from './index';
import FetchPecas from './FetchPecas';

const PecaProvider = (props) => {
  const [pecaItems, setPecaItems] = useState([]);

  async function fetchPecas() {
    try {
      setPecaItems(await FetchPecas());
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors appropriately, e.g., display an error message
    }
  }

  useEffect(() => {
    fetchPecas();
    // eslint-disable-next-line
  }, [setPecaItems]);

  return (
    <Context.Provider value={{ ...props, pecaItems }}>
      {props.children}
    </Context.Provider>
  );
};

export default PecaProvider;
