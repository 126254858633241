import React, { Fragment } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import PecasTableData from './PecaTableData';
import FetchPecas from '../../../Helper/pecas/peca/FetchPecas';
import DeletarPeca from '../../../Helper/pecas/peca/DeletarPeca';

const ListarPecaContain = () => {
  const listarPecas = async () => {
    const fetchedPecas = await FetchPecas();
    return fetchedPecas;
  };
  const onAceitarDeletarPeca = async (id) => {
    const pecaDeletada = await DeletarPeca(id);
    return pecaDeletada;
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <PecasTableData listarPecas={listarPecas} onAceitarDeletarPeca={onAceitarDeletarPeca} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ListarPecaContain;