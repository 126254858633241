import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";
import { Jwt_token } from "../../../Config/Config";
import { EmailAddress, LoginWithJWT, Password, SignIn } from "../../../Constant";
import man from "../../../assets/images/dashboard/1.png";
import FormHeader from "./FormHeader";
import FormPassword from "./FormPassword";
import { handleResponse } from "../../../Services/Fack.Backend";

const LoginTab = ({ selected }) => {
 const [email, setEmail] = useState("test@gmail.com");
 const [password, setPassword] = useState("test@123");
 const [loading, setLoading] = useState(false);
 const [togglePassword, setTogglePassword] = useState(false);
 const history = useNavigate();

 const [value, setValue] = useState(localStorage.getItem("profileURL") || man);
 const [name, setName] = useState(localStorage.getItem("Name"));

 useEffect(() => {
  localStorage.setItem("profileURL", value);
  localStorage.setItem("Name", name);
 }, [value, name]);

 const loginWithJwt = (e) => {
  e.preventDefault();
  setLoading(true);
  setValue(man);
  setName("Emay Walter");
  const requestOptions = {
   method: "POST",
   headers: { "Content-Type": "application/json" },
   body: JSON.stringify({ email, password }),
  };

  return fetch("/users/authenticate", requestOptions)
   .then(handleResponse)
   .then((user) => {
    localStorage.setItem("token", Jwt_token); // Assuming JWT token is provided by backend
    window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/`;
    return user;
   })
   .catch((error) => {
    setTimeout(() => {
     toast.error("Oppss.. The password is invalid or the user does not have a password.");
    }, 200);
   });
 };

 return (
  <Fragment>
   <Form className="theme-form login-form1">
    <FormHeader selected={selected} />
    <div className="login-social-title1">
     <H5>Sign in with Email</H5>{" "}
    </div>
    {/* ... rest of the form structure */}
    <div>
     <Btn
      attrBtn={{ color: "primary", disabled: loading ? loading : loading, onClick: (e) => loginWithJwt(e) }}
     >
      {loading ? "LOADING..." : LoginWithJWT}
     </Btn>
    </div>
   </Form>
  </Fragment>
 );
};

export default LoginTab;
