import { Fragment } from 'react';
import React, { useContext } from 'react';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import { P } from '../../../../AbstractElements';
import PecaContext from '../../../Helper/pecas/peca';
import PecaDetalhesExibicaoHeader from './PecaDetalhesHeader';

const PecaDetalhesExibicao = (props) => {
  const { pecaItems } = useContext(PecaContext);
  const peca = {
    'descricao': pecaItems[0]?.descricao
  };

  return (
    <Fragment>
      <Col xl="5" className="box-col-6 proorder-xl-3 xl-100">
        <Card>
          <CardBody>
            <div className="pro-group pt-0">
              <PecaDetalhesExibicaoHeader dadosPeca={props.dadosPeca} />
            </div>
            <div className="pro-group">
              <P>{props.dadosPeca.descricao}</P></div><div className="pro-group">
              <Row>
                <Col md="6">
                  <Table>
                    <tbody>
                      <tr>
                        <td><b>{'Altura'} &nbsp;&nbsp;&nbsp;:</b></td>
                        <td>{'100cm'}</td>
                      </tr>
                      <tr>
                        <td><b>{'Largura'} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                        <td> {'100cm'} </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md="6">
                  <Table>
                    <tbody>
                      <tr>
                        <td> <b>{'Fabricante'} &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</b></td>
                        <td>{'3M'}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>{'Material'} &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;</b></td>
                        <td>{'Alumínio'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment >
  );
};
export default PecaDetalhesExibicao;