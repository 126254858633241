import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import PecaListaContain from '../../../../_IA/Component/Pecas/Lista';
import React, { Fragment } from 'react';

const ListarPecasPage = () => {
    return (
      <Fragment>
        <div className=" page-header dash-breadcrumb">
          <Breadcrumbs parent="Peças" title=" Lista de Peças" />
        </div>
        <PecaListaContain />
      </Fragment>
    );
};
export default ListarPecasPage;