import { Breadcrumbs } from '../../../../AbstractElements';
import React, { Fragment } from 'react';
import CadastrarPecaContain from '../../../Component/Pecas/Cadastrar';

const CadastrarPecaPage = () => {
    return (
      <Fragment>
        <div className=" page-header dash-breadcrumb">
          <Breadcrumbs parent="Peças" title="Cadastrar Peça" />
        </div>
        <CadastrarPecaContain />
      </Fragment>
    );
};
export default CadastrarPecaPage;