import React, { Fragment, useState } from 'react';
import { H5 } from '../../../../AbstractElements';
import PecaDetalhes from './PecaDetalhes';
import { CardHeader, Card, Col, Container, Row } from 'reactstrap';
import CadastrarPeca from '../../../Helper/pecas/peca/CadastrarPeca';


const CadastrarPecaContain = () => {
  const [result, setResult] = useState(null); // Initial state
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  const onCadastrarPeca = async (data) => {
    try {
      const result = await CadastrarPeca(data);
      setResult(result);
    } catch (error) {
      // Handle errors appropriately, e.g., display an error message
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12" xl="6">
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader className="pb-0">
                    <H5> Cadastrar peça </H5>
                  </CardHeader>
                  <PecaDetalhes onCadastrarPeca={onCadastrarPeca} />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CadastrarPecaContain;