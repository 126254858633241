import { Home, Box } from 'react-feather';

export const MENUITEMS = [
    {
        menutitle: 'DashBoard',
        Items: [
            { path: `${process.env.PUBLIC_URL}/dashboard/default`, icon: Home, type: 'link', active: false, title: 'Dashboard' },
        ]
    },
    {
        menutitle: 'Peças',
        Items: [
            {
                title: 'Peças', icon: Box, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/pecas`, title: 'Listar', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/pecas/nova`, title: 'Nova', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/pecas/importar`, title: 'Importar', type: 'link' },
                ]
            },
        ]
    },
];