import React, { Fragment, useState } from 'react';
import { Btn } from '../../../../../AbstractElements';
import CommonModal from '../Comum/modal';

const DeletarBtn = (props) => {
  const [modalEstaAberto, setModalEstaAberto] = useState(false);
  const toggle = () => setModalEstaAberto(!modalEstaAberto);
  const styleBtn = props.styleBtn || { width: 86, fontSize: 14, padding: 3, margin: 3 };
  const className = props.className || 'btn-pill btn btn-secondary';
  const title = props.title || 'Atenção!';
  const textoModal = props.textoModal || 'Deseja realmente deletar?';
  const onAceitarDeletarPeca = props.onAceitarDeletarPeca || null;

  return (
    <Fragment>
      <Btn attrBtn={{ style: styleBtn, className: className, onClick: toggle }}> Deletar </Btn>
      <CommonModal isOpen={modalEstaAberto} title={title} toggler={toggle} textoBtnAceitar='Sim' onAceitarDeletarPeca={onAceitarDeletarPeca}> {textoModal} </CommonModal>
    </Fragment>
  );
};

export default DeletarBtn;