import React, { useEffect, useRef, useState, Fragment } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { Image, Btn } from '../../../../AbstractElements';
import { StorageApi } from '../../../api';
import handleDownload from '../../../api/DownloadHandler';

const ImageSlider = (props) => {
  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const { nav1 } = state;
  var slidesParaExibir = 1;

  Object.entries(props.miniaturas).map(([key, value]) => {
    if (value !== null) {
      slidesParaExibir++; // Increment only if value is not null
    }
    return { key, value }; // Return an object with key and value
  });

  useEffect(() => {
    setState({
      nav1: slider1.current,
    });
  }, []);


  //If the thumbnail slider is clonning content, it's necessary to use the property slidesToShow to solve it
  return (
    <Fragment>
      <Col xl="10" className="product-main">
        <div className="pro-slide-single">
          <Slider
            ref={(slider) => (slider1.current = slider)}
          >
            {props.imagens.paths && Array.isArray(props.imagens.paths) && props.imagens.paths.length > 0 ? (
              <Image
                attrImage={{
                  src: `${StorageApi}/${props.imagens.paths[0]}`,
                  alt: '',
                  className: 'img-fluid',
                }}
              />
            ) : (
              <p>Imagem inexistente.</p>
            )}
          </Slider>
        </div>
      </Col>
      <Col xl="2" className="product-thumbnail">
        <div className="pro-slide-right">
          <Slider
            asNavFor={nav1}
            ref={(slider) => (slider1.current = slider)}
            slidesToShow={slidesParaExibir}
            speed={50}
            swipeToSlide={true}
            focusOnSelect={true}
            vertical={true}
          >
            {props.imagens.paths && Array.isArray(props.imagens.paths) && props.imagens.paths.length > 0 ? (
              <Image
                attrImage={{
                  src: `${StorageApi}/${props.imagens.paths[0]}`,
                  alt: '',
                  className: 'img-fluid',
                }}
                key={`image`}
              />
            ) : (
              <p>Imagem inexistente.</p>
            )}
            
            {props.miniaturas?.html && (
              <Btn 
                attrBtn={{ 
                  color: 'info', 
                  outline: true,
                  size: 'sm'
                }} 
                key={'visualizar'}
              >
                Visualizar
              </Btn>
            )}

            {props.miniaturas?.pdf3d && (
              <Btn 
                attrBtn={{ 
                  color: 'primary', 
                  outline: true, 
                  onClick:() => handleDownload(props.miniaturas.pdf3d)
                }} 
                key={'pdf3d'}
              >
                PDF 3D
              </Btn>
            )}

            {props.miniaturas?.pdf2d && (
              <Btn 
                attrBtn={{ 
                  color: 'primary', 
                  outline: true, 
                  onClick:() => handleDownload(props.miniaturas.pdf2d)
                }} 
                key={'pdf2d'}
              >
                PDF 2D
              </Btn>
            )}

            {props.miniaturas?.solidWorks && (
              <Btn 
                attrBtn={{ 
                  color: 'primary', 
                  outline: true,
                  size: 'sm',
                  onClick:() => handleDownload(props.miniaturas.solidWorks)
                }} 
                key={'solidWorks'}
              >
                Solid Works
              </Btn>
            )}

            {props.miniaturas?.slt && (
              <Btn 
                attrBtn={{ 
                  color: 'primary', 
                  outline: true, 
                  onClick:() => handleDownload(props.miniaturas.slt)
                }} 
                key={'SLT'}
              >
                SLT
              </Btn>
            )}
          </Slider>
        </div>
      </Col>
    </Fragment>
  );
};
export default ImageSlider;
