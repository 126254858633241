import React, { Fragment, useState, useContext } from 'react';
import Ratings from 'react-ratings-declarative';
import { H3 } from '../../../../AbstractElements';
import { ProductReview } from '../../../../Constant';
import PecaContext from '../../../Helper/pecas/peca';

const PecaDetalhesHeader = (props) => {
  const [rating, setRating] = useState(0);
  const { pecaItems } = useContext(PecaContext);

  const changeRating = (newRating) => {
    setRating(newRating);
  };
  const peca = {
    'nome': pecaItems[0]?.nome
  };
  return (
    <Fragment>
      <div className="product-page-details">
        <H3>{props.dadosPeca.nome}</H3>
        <div className="pro-review">
          <div className="d-flex">
          <Ratings rating={rating} widgetRatedColors="blue" widgetDimensions="20px"
            widgetSpacings="0px" changeRating={changeRating}>
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
          </Ratings>
          <span>{ProductReview}</span> 
          </div> 
        </div>
      </div>
    </Fragment>
  );
};
export default PecaDetalhesHeader;