import React, { Fragment } from 'react';
import { Col, CardBody, CardFooter, Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import SweetAlert from 'sweetalert2';
import { Btn } from '../../../../AbstractElements';


const PecaDetalhes = ({ onCadastrarPeca }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const dadosFormulario = {
    nome: '',
    descricao: '',
    arquivoPrincipal: null,
    HTML: null,
    PDF3D: null,
    PDF2D: null,
    projetoSolidWorks: null,
    SLT: null,
  };

  const handleLeituraDeArquivo = async (arquivo, chave) => {
    return new Promise((resolve, reject) => {
      if (arquivo) {
        const reader = new FileReader();
        reader.onload = (e) => {
          dadosFormulario[chave] = e.target.result;
          resolve();
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(arquivo);
      }  else {
        resolve(); // Resolve even if no file is selected
      }
    });
  };

  const onSubmit = async (dados) => {
    if (dados !== '') {

      const fileArquivoPrincipal = dados.fileArquivoPrincipal[0];
      if (fileArquivoPrincipal) {
        let uploadPromises = [];

        uploadPromises.push(handleLeituraDeArquivo(fileArquivoPrincipal, 'arquivoPrincipal'));

        const fileHTML = dados.fileHTML[0];
        if (fileHTML) {
          uploadPromises.push(handleLeituraDeArquivo(fileHTML, 'HTML'));
        }

        const filePDF3D = dados.filePDF3D[0];
        if (filePDF3D) {
          uploadPromises.push(handleLeituraDeArquivo(filePDF3D, 'PDF3D'));
        }

        const filePDF2D = dados.filePDF2D[0];
        if (filePDF2D) {
          uploadPromises.push(handleLeituraDeArquivo(filePDF2D, 'PDF2D'));
        }

        const fileProjetoSolidWorks = dados.fileProjetoSolidWorks[0];
        if (fileProjetoSolidWorks) {
          uploadPromises.push(handleLeituraDeArquivo(fileProjetoSolidWorks, 'projetoSolidWorks'));
        }

        const fileSLT = dados.fileSLT[0];
        if (fileSLT) {
          uploadPromises.push(handleLeituraDeArquivo(fileSLT, 'SLT'));
        }

        dadosFormulario.nome = dados.txtNome;
        dadosFormulario.descricao = dados.txtAreaDescricao;

        try {
          await Promise.all(uploadPromises);
          onCadastrarPeca(dadosFormulario).then(() => {
            SweetAlert.fire({
              title: 'Feito!',
              text: 'Cadastro efetuado com sucesso',
              icon: 'success',
            });
          });
        } catch (error) {
          console.error('Erro no upload de arquivos:', error);
          // Handle errors if any file upload fails
        }
      } else {
        // Handle the case where no file is selected (optional)
        errors.fileArquivoPrincipal = 'Campo imagem principal é obrigatório';
        errors.showMessages();
      }
    } else {
      errors.showMessages();
    }
  }

  return (
    <Fragment>
      <Form className="theme-form form-default needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="txtNome"> Nome * </Label>
            <Col sm="9">
              <input {...register('txtNome', { required: true })} id="txtNome" className="form-control" type="text" placeholder="Nome" />
              <span>{errors.txtNome && 'Nome da peça é obrigatório'}</span>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="txtAreaDescricao" > Descrição </Label>
            <Col sm="9">
            <textarea {...register('txtAreaDescricao')} id="txtAreaDescricao" className="form-control" rows="3" cols="30" ></textarea>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="fileArquivoPrincipal"> Imagem principal * </Label>
            <Col sm="9">
              <input {...register('fileArquivoPrincipal', { required: true })} id="fileArquivoPrincipal" className="form-control" type="file" />
              <span>{errors.fileArquivoPrincipal && 'Campo imagem principal é obrigatório'}</span>
            </Col>
          </FormGroup>
          <br /> <hr />
          <p>Arquivos de projeto da peça</p>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="fileHTML" > HTML </Label>
            <Col sm="9">
              <input {...register('fileHTML')} id="fileHTML" className="form-control" type="file" />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="filePDF3D" > PDF 3D </Label>
            <Col sm="9">
              <input {...register('filePDF3D')} id="filePDF3D" className="form-control" type="file" />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="filePDF2D" > PDF 2D </Label>
            <Col sm="9">
              <input {...register('filePDF2D')} id="filePDF2D" className="form-control" type="file" />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="fileProjetoSolidWorks" > Projeto do Solid Works </Label>
            <Col sm="9">
              <input {...register('fileProjetoSolidWorks')} id="fileProjetoSolidWorks" className="form-control" type="file" />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="fileSLT" > SLT </Label>
            <Col sm="9">
              <input {...register('fileSLT')} id="fileSLT" className="form-control" type="file" />
            </Col>
          </FormGroup>
        </CardBody>
        <CardFooter className="pt-0">
          <Btn attrBtn={{ color: 'primary', className: 'me-2'}}> Enviar </Btn>
          <Btn attrBtn={{ color: 'secondary', onClick: () => reset() }}> Cancelar </Btn>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default PecaDetalhes;