import React, { Fragment, useState } from 'react';
import { Col, CardBody, CardFooter, Form, FormGroup, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Bell } from 'react-feather';
import { Btn, Image, Alerts, P } from '../../../../AbstractElements';
import SweetAlert from 'sweetalert2';
import { StorageApi } from '../../../api';


const PecaDetalhes = ( props ) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { dadosPeca, onAlterarPeca } = props;
  
  const [arquivoPrincipalEnviado, setArquivoPrincipalEnviado] = useState(false); // State to track uploaded file
  const [caixaAlertaAberta, setCaixaAlertaAberta] = useState(true);
  const toggleCaixaAlerta = () => setCaixaAlertaAberta(!caixaAlertaAberta);

  const onSubmit = (data) => {
    if (data !== '') {
      const formData = {
        nome: data.txtNome,
        descricao: data.txtAreaDescricao,
      };
  
      if (arquivoPrincipalEnviado) {
        const file = data.fileArquivoPrincipal[0]; // Get the selected file
        const reader = new FileReader();
        reader.onload = (event) => {
          formData.arquivoPrincipal = event.target.result;
        };
        reader.readAsDataURL(file); // Read the file contents as a DataURL
      }
      onAlterarPeca(dadosPeca.id, formData).then(() => {
        SweetAlert.fire({ 
          title: 'Feito!', 
          text: 'Alteração efetuada com sucesso', 
          icon: 'success',
          html: (
            <Link to="/pecas">
              Ir para listagem
            </Link>
          )
        });
      });
    } else {
      errors.showMessages();
    }
  }

  const onReset = () => {
    reset();
    setCaixaAlertaAberta(false);
  };

  return (
    <Fragment>
      <Form className="theme-form form-default needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="txtNome">Nome *</Label>
            <Col sm="9">
              <input {...register('txtNome', { required: true })} className="form-control" name="txtNome" type="text" placeholder="Nome" defaultValue={dadosPeca.nome && dadosPeca.nome} />
              <span>{errors.txtNome && 'Nome da peça é obrigatório'}</span>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="txtAreaDescricao" > Descrição </Label>
            <Col sm="9">
              <textarea {...register('txtAreaDescricao')} id="txtAreaDescricao" className="form-control" rows="3" cols="30" defaultValue={dadosPeca.descricao && dadosPeca.descricao} ></textarea>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="fileArquivoPrincipal" >Imagem principal *</Label>
            <Col sm="9">
              <input {...register('fileArquivoPrincipal')} id="fileArquivoPrincipal" className="form-control" type="file" onChange={(event) => setArquivoPrincipalEnviado(event.target.files.length > 0)} />
              <span>{errors.fileArquivoPrincipal && 'Campo imagem principal é obrigatório'}</span>
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Col sm="12">
              <Image attrImage={{ src: `${StorageApi}/${dadosPeca.arquivoPrincipal}`, style: { width: 200, height: 200 }, alt: '' }} />
              <span> 
                {arquivoPrincipalEnviado && 
                  <Alerts 
                    attrAlert={{
                      className: 'alert-dismissible', color: 'warning outline',
                      isOpen: caixaAlertaAberta,
                      target: 'Alert-1',
                    }}
                    closeBtn={true}
                    attrBtn={{ className: 'btn-close', color: 'default', id: 'Alert-1', onClick: toggleCaixaAlerta }}
                  >
                    <Bell />
                    <P>Arquivo acima sera alterado.</P>
                  </Alerts>
                }
              </span>
            </Col>
          </FormGroup>
          <br /> <hr />
          <p>Arquivos de projeto da peça</p>
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="fileHTML" > HTML </Label>
            <Col sm="9">
              <input {...register('fileHTML')} id="fileHTML" className="form-control" type="file" />
            </Col>
          </FormGroup>
          {dadosPeca.HTML && (
            <p></p>
          )}
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="filePDF3D" > PDF 3D </Label>
            <Col sm="9">
              <input {...register('filePDF3D')} id="filePDF3D" className="form-control" type="file" />
            </Col>
          </FormGroup>
          {dadosPeca.PDF3D && (
            <p></p>
          )}
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="filePDF2D" > PDF 2D </Label>
            <Col sm="9">
              <input {...register('filePDF2D')} id="filePDF2D" className="form-control" type="file" />
            </Col>
          </FormGroup>
          {dadosPeca.PDF2D && (
            <p></p>
          )}
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="fileProjetoSolidWorks" > Projeto do Solid Works </Label>
            <Col sm="9">
              <input {...register('fileProjetoSolidWorks')} id="fileProjetoSolidWorks" className="form-control" type="file" />
            </Col>
          </FormGroup>
          {dadosPeca.projetoSolidWorks && (
            <p></p>
          )}
          <FormGroup className="row">
            <Label className="col-sm-3 col-form-label" htmlFor="fileSLT" > SLT </Label>
            <Col sm="9">
              <input {...register('fileSLT')} id="fileSLT" className="form-control" type="file" />
            </Col>
          </FormGroup>
          {dadosPeca.SLT && (
            <p></p>
          )}
        </CardBody>
        <CardFooter className="pt-0">
          <Btn attrBtn={{ color: 'primary', className: 'me-2' }}>Enviar</Btn>
          <Btn attrBtn={{ color: 'secondary', onClick: () => onReset() }}>Cancelar</Btn>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default PecaDetalhes;