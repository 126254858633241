import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { StorageApi } from '../../api';
import { Btn, H6, Image } from '../../../AbstractElements';
import DeletarBtn from '../../Component/Elementos/Modals/Basicos/DeletarBtn';


const styleImage = { width: 100, height: 100};
const styleBtns = { width: 86, fontSize: 14, padding: 3, margin: 3 };

const pecaCols = [
  {
    name: 'Imagem',
    selector: (row) => row.imagem,
    sortable: true,
    center: true,
  },
  {
    name: 'Detalhes',
    selector: (row) => row.detalhes,
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: 'Data de cadastro',
    selector: (row) => row.criado_em,
    sortable: true,
    center: true,
  },
  {
    name: 'Ações',
    selector: (row) => row.acoes,
    center: true,
  },
];

const PecasLista = (listarPecas, onAceitarDeletarPeca) => {
  const [pecasDados, setPecasDados] = useState([]); // Initialize empty array

  useEffect(() => {
    listarPecas().then((pecas) => {
      const dadosProcessados = pecas.map((peca) => ({
        imagem: (
          <Image
            attrImage={{ src: `${StorageApi}/${peca.pathArquivoPrincipal}`, style: styleImage, alt: '' }}
          />
        ),
        detalhes: (
          <div>
            <H6>{peca.nome}</H6>
            <p>{peca.descricao}</p>
          </div>
        ),
        criado_em: peca.formatado_created_at,
        acoes: (
          <div>
            <span className="ms-2">
              <Link to={`${process.env.PUBLIC_URL}/pecas/${peca.id}`} >
                <Btn attrBtn={{ style: styleBtns, className: 'btn-pill btn btn-light', type: 'button' }}>
                  Visualizar
                </Btn>
              </Link>
            </span>
            <span>
              <Link to={`${process.env.PUBLIC_URL}/pecas/alterar/${peca.id}`} >
                <Btn attrBtn={{ style: styleBtns, className: 'btn-pill btn btn-success', type: 'button'}}>
                  Editar
                </Btn>
              </Link>
            </span>
            <span>
              <DeletarBtn onAceitarDeletarPeca={() => onAceitarDeletarPeca(peca.id)} />
            </span>
          </div>
        ),
      }));
      setPecasDados(dadosProcessados); // Update state with processed Dados
    });
  }, []);

  return pecasDados;
};

export default PecasLista;
export const pecasColunas = pecaCols;