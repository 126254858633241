// Content copied from Pages/Ecommerce/ProductPage
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import ExibirPecaContain from '../../../../_IA/Component/Pecas/Exibir';
import React, { Fragment } from 'react';

const ExibirPecaPage = () => {
    return (
      <Fragment>
        <div className=" page-header dash-breadcrumb">
          <Breadcrumbs parent="Peças" title=" Exibir Peça" />
        </div>
        <ExibirPecaContain />
      </Fragment>
    );
};
export default ExibirPecaPage;