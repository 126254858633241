import { Breadcrumbs } from '../../../../AbstractElements';
import React, { Fragment } from 'react';
import AlterarPecaContain from '../../../Component/Pecas/Alterar';

const AlterarPecaPage = () => {
    return (
      <Fragment>
        <div className=" page-header dash-breadcrumb">
          <Breadcrumbs parent="Peças" title="Alterar Peça" />
        </div>
        <AlterarPecaContain />
      </Fragment>
    );
};
export default AlterarPecaPage;