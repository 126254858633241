import { React, Fragment} from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ImageSlider from './ImageSlider';
import PecaDetalhes from './PecaDetalhes';
import GetPecaDados from '../../../Data/Pecas/peca-by-id';

const ExibirPecaContain = () => {
 const { peca, isLoading } = GetPecaDados();

  const dadosImageSlider = {
    paths: [], // Initialize with an empty array
  };
  const pathsMiniaturasSlider = {
    html: '',
    pdf2d: '',
    pdf3d: '',
    solidWorks: '',
    slt: '',
  }; 
  const dadosPecaDetalhes = {
    nome: '',
    descricao: '',
  };
  
  if (peca) {
    dadosImageSlider.paths = [peca.pathArquivoPrincipal];
    dadosPecaDetalhes.nome = peca.nome;
    dadosPecaDetalhes.descricao = peca.descricao;
    dadosPecaDetalhes.descricao = peca.descricao;
    pathsMiniaturasSlider.html = peca.pathHTML;
    pathsMiniaturasSlider.pdf2d = peca.pathPDF2D;
    pathsMiniaturasSlider.pdf3d = peca.pathPDF3D;
    pathsMiniaturasSlider.solidWorks = peca.pathProjetoSolidWorks;
    pathsMiniaturasSlider.slt = peca.pathSLT;
  }

  return (
    <Fragment>
      <Container fluid={true}>
        {isLoading ? (
          <div className="loading-indicator">Carregando...</div>
        ) : (
          <div>
            <Row className="product-page-main p-0">
              <Col xl="7" md="7" className="box-col-12 xl-50">
                <Card>
                  <CardBody>
                    <Row>
                      <ImageSlider imagens={dadosImageSlider} miniaturas={pathsMiniaturasSlider} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <PecaDetalhes dadosPeca={dadosPecaDetalhes} />
            </Row>
          </div>
        )}
      </Container>
    </Fragment>
  );
};
export default ExibirPecaContain;