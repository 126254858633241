import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FetchPecaById } from '../../Helper/pecas/peca/FetchPecas';

export default function GetPecaDados() { // Function name for clarity
  const { id } = useParams();
  const [peca, setPeca] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const fetchedPeca = await FetchPecaById(id);
        setPeca(fetchedPeca);
      } catch (error) {
        setError(error);
        console.error('Error fetching peca:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // No return statement or JSX here, 
  // as we're not creating a component

  return { peca, isLoading, error }; // Return all three variables
}